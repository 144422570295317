/* eslint-disable object-curly-newline */
import React from 'react';
import { Heading, Stack } from '@chakra-ui/react';
import LandingLayout from '../components/layouts/landing';
import Construccion from '../components/ui/assets/construccion';

function OnConstructionView() {
  return (
    <LandingLayout>
      <Stack
        align="center"
        justify={{ base: 'center', md: 'space-around', xl: 'space-between' }}
        wrap="no-wrap"
        minH="70vh"
        p={8}
        m={8}
      >
        <Heading as="h2" textAlign="center" fontWeight="thin" fontSize="5xl">
          Sitio en construcción
        </Heading>
        <Construccion />
      </Stack>
    </LandingLayout>
  );
}

export default OnConstructionView;
