import React from 'react';
import { chakra, Flex, useToken } from '@chakra-ui/react';
import { ReactComponent as ConstSVG } from './construccion.svg';

function Construccion() {
  const ConstChakra = chakra(ConstSVG);
  const [colorText] = useToken('colors', ['chakra-body-text']);

  return (
    <Flex
      w="100%"
      h="100%"
      align="center"
      justify="center"
      sx={{
        '.construccionTrazo': {
          fill: colorText,
        },
      }}
    >
      <ConstChakra w={['100%', '100%']} />
    </Flex>
  );
}

export default Construccion;
