/* eslint-disable prettier/prettier */
import React from 'react';
import {
  Flex,
} from '@chakra-ui/react';
import LandingLayout from '../components/layouts/landing';
import Error404 from '../components/ui/assets/404_error';

function ErrorView() {
  return (
    <LandingLayout>
      <Flex direction="row" align="center" justify="center" h="75vh">
        <Error404 />
      </Flex>
    </LandingLayout>
  );
}

export default ErrorView;
