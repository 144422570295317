import React from 'react';
import { Icon } from '@chakra-ui/react';
import { FaBars } from 'react-icons/fa';
import { IoClose } from 'react-icons/io5';

export function MenuIcon() {
  return <Icon as={FaBars} />;
}

export function CloseIcon() {
  return <Icon as={IoClose} />;
}
