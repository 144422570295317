import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { chakra } from '@chakra-ui/react';

// https://dev.to/abachi/how-to-change-svg-s-color-in-react-42g2
// https://stackoverflow.com/questions/35432020/stylizing-svg-element-using-reactjs-with-css-modules#36451518
import { ReactComponent as LogoSVG } from './logo_ciclos.svg';

function LogoCiclos({ tamanios, to }) {
  const Ciclos = chakra(LogoSVG, {
    baseStyle: {
      w: tamanios,
      h: tamanios,
    },
  });

  return (
    <Link to={to}>
      <Ciclos
        fill="white"
        sx={{
          '@media screen and (min-width: 48em)': {
            '#primera': {
              fill: '#73cdd6',
            },
            '#segunda': {
              fill: '#0b4656',
            },
            '#tercera': {
              fill: '#1b7788',
            },
            '#cuarta': {
              fill: '#94bbc3',
            },
          },
        }}
      />
    </Link>
  );
}

LogoCiclos.propTypes = {
  tamanios: PropTypes.arrayOf(PropTypes.string).isRequired,
  to: PropTypes.string,
};

LogoCiclos.defaultProps = {
  to: '/',
};

export default LogoCiclos;
