import React from 'react';
import { Flex, useColorMode, useToken } from '@chakra-ui/react';

import { ReactComponent as Error404SVG } from './404_error.svg';
import './404_styles.css';

function Error404() {
  const { colorMode } = useColorMode();
  const [colorText] = useToken('colors', ['chakra-body-text']);
  const primerRect = colorMode === 'dark' ? 0.2 : 0.8;
  const segundoRect = colorMode === 'dark' ? 0.1 : 0.2;

  return (
    <Flex
      align="center"
      justify="center"
      sx={{
        '#freepik--page-not-found--inject-2 > g > g > path.letra': {
          fill: colorText,
        },
        '#freepik--page-not-found--inject-2 > rect[0]': {
          opacity: primerRect,
        },
        '#freepik--page-not-found--inject-2 > rect[1]': {
          opacity: segundoRect,
        },
      }}
    >
      <Error404SVG height="75vh" width="100vw" />
    </Flex>
  );
}

export default Error404;
