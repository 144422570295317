import React from 'react';
import {
  Box,
  Flex,
  Heading,
  ListItem,
  Spacer,
  Text,
  UnorderedList,
} from '@chakra-ui/react';
import LandingLayout from '../components/layouts/landing';

export default function AvisoPrivacidadView() {
  const estiloDefault = { color: 'rgb(87, 109, 150)' };
  const estiloEnlace = {
    color: 'inherit !important',
    textDecoration: 'none !important',
    fontSize: 'inherit !important',
  };

  return (
    <LandingLayout>
      <Flex flexDir="column" gap="1rem">
        <Heading as="h2">
          <span style={estiloDefault}>Aviso de Privacidad</span>
        </Heading>

        <Text style={estiloDefault}>
          Su privacidad es importante para nosotros. Es política de este sitio
          web respetar su privacidad respecto a cualquier información personal
          que podamos recolectar en el sitio web
          <a href="https://luzma.gudin.io">Luz María Gudiño</a>, y en los otros
          sitios que posemos y operamos.
        </Text>

        <Text>
          <span style={estiloDefault}>
            Solicitamos información personal solo cuando realmente la requerimos
            para prestar un servicio. Lo hacemos a través de medios justos y
            legales, bajo su conocimiento y consentimiento. También le
            informamos por qué la estamos recolectando y cómo será utilizada.
          </span>
        </Text>

        <Text>
          <span style={estiloDefault}>
            Solo retenemos la información recolectada durante el tiempo que sea
            necesario para brindarle el servicio solicitado. Cuando recolectamos
            datos, los protegemos dentro de medios comercialmente aceptables,
            con el fin de evitar pérdidas y robos, así como el acceso, la
            divulgación, la copia, el uso o la modificación no autorizada.
          </span>
        </Text>

        <Text>
          <span style={estiloDefault}>
            No compartimos públicamente ni con terceros su información de
            identificación personal, excepto cuando es legalmente exigido.
          </span>
        </Text>

        <Text>
          <span style={estiloDefault}>
            Nuestra plataforma puede tener enlaces hacia sitios web externos que
            no son operados por nosotros. Le indicamos que no tenemos ningún
            tipo de control sobre el contenido ni las prácticas de esos sitios,
            y que no nos hacemos responsables por sus respectivos&nbsp;
          </span>

          <a
            href="https://avisodeprivacidad.info/"
            rel="noopener noreferrer"
            target="_blank"
            style={estiloDefault}
          >
            avisos de privacidad
          </a>
          <span style={estiloDefault}>.</span>
        </Text>

        <Text>
          <span style={estiloDefault}>
            Usted está en la libertad de rechazar nuestra solicitud de
            información personal, pero asimismo comprende que tal vez no le
            podremos prestar algunos de los servicios deseados.
          </span>
        </Text>

        <Text>
          <span style={estiloDefault}>
            El uso continuo de nuestro sitio será considerado como aceptación de
            nuestras prácticas respecto a la privacidad y a la información
            personal.
          </span>
        </Text>

        <Text>
          <span style={estiloDefault}>
            Si tiene alguna inquietud referente a la forma como administramos
            los datos de nuestros usuarios y la información personal, póngase en
            contacto con nosotros.
          </span>
        </Text>

        <Flex flexDir="column">
          <Box style={estiloDefault}>
            <Heading as="h2">Política de Cookies Luz María Gudiño</Heading>

            <Heading as="h3">¿Qué son las cookies?</Heading>

            <Text>
              Tal como se acostumbra en casi todos los sitios web profesionales,
              este sitio usa cookies, que son pequeños archivos que se almacenan
              en su computadora para mejorar su experiencia.
            </Text>

            <Text>
              Esta página describe cuáles son los datos recolectados por las
              cookies, cómo las utilizamos, y por qué en ocasiones es necesario
              permitirlas.
            </Text>

            <Text>
              También le indicamos cómo puede evitar que las cookies sean
              almacenadas. Sin embargo, tenga presente que esto puede alterar el
              buen funcionamiento de algunos elementos de la plataforma.
            </Text>

            <Heading as="h3">¿Cómo usamos las cookies?</Heading>

            <Text>
              Utilizamos cookies por varias razones, como detallamos a
              continuación.
            </Text>

            <Text>
              En la mayoría de las situaciones, no existen opciones que le
              permitan desactivar las cookies sin afectar completamente su
              funcionalidad y los recursos proporcionados por ellas.
            </Text>

            <Text>
              Por eso, es recomendable que permita todas las cookies
              relacionadas con algunos de los servicios que está utilizando, aún
              cuando no sepa si son necesarias.
            </Text>

            <Heading as="h3">Desactivar las cookies</Heading>

            <Text>
              Usted puede impedir las cookies, ajustando las configuraciones de
              su navegador (consulte el área de ayuda de su navegador para saber
              más al respecto).
            </Text>

            <Text>
              Tenga presente que la desactivación de las cookies afectará las
              funcionalidades y los recursos de este sitio y de muchos otros al
              visitarlos. Por eso, es recomendable que no las desactive.
            </Text>

            <Heading as="h3">Las cookies que definimos</Heading>

            <Heading as="h4">Cookies relacionadas con la cuenta:</Heading>

            <UnorderedList>
              <ListItem>
                Si usted crea una cuenta con nosotros, utilizaremos cookies para
                administrar el proceso de inscripción y para la gestión en
                general. Normalmente, estas cookies son excluidas cuando usted
                sale del sistema. Sin embargo, en algunos casos, pueden
                permanecer para recordar sus preferencias del sitio.
              </ListItem>
            </UnorderedList>

            <Heading as="h4">Cookies relacionadas con el login:</Heading>

            <UnorderedList>
              <ListItem>
                Usamos cookies cuando usted inicia su sesión. Esto ayuda a que
                recordemos esa acción y le evita volver a introducir sus datos
                cada vez que visita una página. Estas cookies también le
                garantizan que puede acceder a recursos y a áreas vinculadas al
                inicio de sesión y, por lo general, son eliminadas cuando la
                cierra.
              </ListItem>
            </UnorderedList>

            <Heading as="h4">
              Cookies relacionadas con los boletines vía email:
            </Heading>

            <UnorderedList>
              <ListItem>
                Este sitio le ofrece servicios de suscripción al boletín
                informativo (newsletter). Las cookies permiten recordar si usted
                ya efectuó su registro, o si se deben mostrar ciertas
                notificaciones que son válidas solamente para usuarios
                inscritos/no inscritos.
              </ListItem>
            </UnorderedList>

            <Heading as="h4">Pedidos processando cookies relacionados</Heading>

            <UnorderedList>
              <ListItem>
                Este sitio cuenta con facilidades de pago o comercio
                electrónico, por lo que algunas cookies son esenciales para
                garantizar que su pedido es recordado entre las páginas, y para
                que lo podamos procesar de la forma adecuada.
              </ListItem>
            </UnorderedList>

            <Heading as="h4">Cookies relacionadas con las búsquedas</Heading>

            <UnorderedList>
              <ListItem>
                Periódicamente, le ofrecemos búsquedas y cuestionarios para
                brindarle información interesante, herramientas útiles, y para
                comprender nuestra base de usuarios con mayor precisión. Esas
                búsquedas pueden utilizar cookies para recordar quién participó
                en la misma, o para brindarle resultados precisos después de la
                modificación de las páginas.
              </ListItem>
            </UnorderedList>

            <Heading as="h4">Cookies relacionadas con los formularios:</Heading>

            <UnorderedList>
              <ListItem>
                Cuando envía datos por medio de formularios, como los que se
                encuentran en la página de contacto o en los comentarios, las
                cookies se pueden configurar para recordar los detalles del
                usuario relacionados con los futuros envíos.
              </ListItem>
            </UnorderedList>

            <Heading as="h4">Cookies de las preferencias del sitio:</Heading>

            <UnorderedList>
              <ListItem>
                Con el fin de proporcionarle una experiencia adecuada, le
                ofrecemos una funcionalidad para que establezca sus preferencias
                respecto a la forma como este sitio es ejecutado cuando lo
                utiliza. Y para recordarlas, es necesario definir las cookies.
                De esta manera, su elección se ve reflejada siempre que usted
                interactúa con una página afectada por la definición de dichas
                preferencias.
              </ListItem>
            </UnorderedList>

            <Heading as="h3">Cookies de Terceros:</Heading>

            <UnorderedList>
              <ListItem>
                Este sitio usa Google Analytics, una de las soluciones de
                análisis más difundidas de internet, que nos ayuda a entender la
                forma en que usted usa el sitio y cómo podemos mejorar su
                experiencia. Entre los ítems que rastrean estas cookies, están
                el tiempo que usted invierte en el sitio y las páginas
                visitadas. Con base en esta información, podemos seguir creando
                contenido atractivo.Para mayor información sobre estas cookies,
                visite la página oficial de Google Analytics.
              </ListItem>

              <ListItem>
                Periódicamente, probamos nuevos recursos y hacemos alteraciones
                ligeras en la forma como presentamos el sitio. Cuando estamos en
                tiempo de experimentación, las cookies pueden ser usadas para
                garantizarle una experiencia de navegación consistente, mientras
                analizamos cuáles son las optimizaciones preferidas por nuestros
                usuarios.
              </ListItem>

              <ListItem>
                Por otro lado, debemos entender las estadísticas relacionadas
                con cuántos visitantes de nuestro sitio efectúan una compra, y
                son las cookies quienes rastrean ese tipo de datos.
              </ListItem>

              <ListItem>
                Esto es importante para usted ya que nos permite hacer
                proyecciones de negocios con precisión, analizar costos de
                publicidad y definir productos para asegurarle el mejor precio
                posible.
              </ListItem>

              <ListItem>
                El servicio Google AdSense que utilizamos para gestionar la
                publicidad usa una cookie DoubleClick que le presenta anuncios
                más relevantes y limita el número de veces en que es exhibido.
                Para más información, consulte las Preguntas Frecuentes
                oficiales sobre la privacidad de Google AdSense.
              </ListItem>

              <ListItem>
                También utilizamos anuncios para compensar los gastos de
                funcionamiento de este sitio y conseguir fondos para futuros
                desarrollos. Las cookies de publicidad comportamental usadas en
                este sitio fueron pensadas para garantizar que usted reciba los
                anuncios más relevantes siempre que sea posible, rastreando sus
                preferencias de forma anónima y presentándole contenidos
                semejantes que puedan ser de su interés.
              </ListItem>
            </UnorderedList>

            <Flex>
              <UnorderedList>
                <ListItem>
                  <Text style={estiloDefault}>
                    El servicio Google AdSense que utilizamos para gestionar la
                    publicidad usa una cookie DoubleClick que le presenta
                    anuncios más relevantes y limita el número de veces en que
                    es exhibido. Para más información, consulte las Preguntas
                    Frecuentes oficiales sobre la privacidad de Google AdSense.
                  </Text>
                </ListItem>

                <ListItem>
                  <Text style={estiloDefault}>
                    También utilizamos anuncios para compensar los gastos de
                    funcionamiento de este sitio y conseguir fondos para futuros
                    desarrollos. Las cookies de publicidad comportamental usadas
                    en este sitio fueron pensadas para garantizar que usted
                    reciba los anuncios más relevantes siempre que sea posible,
                    rastreando sus preferencias de forma anónima y presentándole
                    contenidos semejantes que puedan ser de su interés.
                  </Text>
                </ListItem>

                <ListItem>
                  <Text style={estiloDefault}>
                    Finalmente, hay diversos socios que anuncian en nuestro
                    nombre, y las cookies de rastreo nos permiten ver si
                    nuestros clientes accedieron al sitio desde la plataforma de
                    un afiliado. De esta forma, podemos compensarlos
                    apropiadamente y, cuando aplique, permitir que dichos
                    afiliados le ofrezcan promociones.
                  </Text>
                </ListItem>
              </UnorderedList>

              <Spacer />
            </Flex>

            <Heading as="h3">Compromisos del Usuario</Heading>

            <Text>
              El usuario se compromete a hacer un uso adecuado de los contenidos
              y de la información que Luz María Gudiño ofrece en el sitio y, con
              carácter indicativo, pero no limitado, a:
            </Text>

            <UnorderedList>
              <ListItem>
                A) No involucrarse en actividades ilegales o que sean contrarias
                a la buena fe y al orden público;
              </ListItem>

              <ListItem>
                B) No difundir contenidos ni publicidad de índole racista,
                xenofóbico, Onde Bola o azar, pornografía, terrorismo o que esté
                en contra de los derechos humanos;
              </ListItem>

              <ListItem>
                C) No causar daños a los sistemas físicos (hardware) y lógicos
                (software) de Luz María Gudiño de sus proveedores o terceros,
                para introducir o distribuir virus informáticos u otros sistemas
                de hardware o software que sean capaces de producir los
                perjuicios anteriormente mencionados.
              </ListItem>
            </UnorderedList>

            <Spacer />
          </Box>
        </Flex>

        <Heading as="h3" style={estiloDefault}>
          Compromisos del Usuario
        </Heading>

        <Text style={estiloDefault}>
          El usuario se compromete a hacer un uso adecuado de los contenidos y
          de la información que Luz María Gudiño ofrece en el sitio y, con
          carácter indicativo, pero no limitado, a:
        </Text>

        <UnorderedList>
          <ListItem style={estiloDefault}>
            A) No involucrarse en actividades ilegales o que sean contrarias a
            la buena fe y al orden público;
          </ListItem>

          <ListItem style={estiloDefault}>
            B) No difundir contenidos ni publicidad de índole racista,
            xenofóbico,
            <a
              href="https://avisodeprivacidad.info/"
              rel="nofollow noreferrer"
              target="_BLANK"
              style={estiloEnlace}
            >
              aviso de privacidad
            </a>
            {
              ' o azar, pornografía, terrorismo o que esté en contra de los derechos humanos;'
            }
          </ListItem>

          <ListItem style={estiloDefault}>
            C) No causar daños a los sistemas físicos (hardware) y lógicos
            (software) de Luz María Gudiño, de sus proveedores o terceros, para
            introducir o distribuir virus informáticos u otros sistemas de
            hardware o software que sean capaces de producir los perjuicios
            anteriormente mencionados.
          </ListItem>
        </UnorderedList>

        <Heading as="h3" style={estiloDefault}>
          Más información
        </Heading>

        <Text style={estiloDefault}>
          Esperamos que esta información sea clara. Y como mencionado
          anteriormente: cuando no tenga seguridad de que necesita las cookies,
          es más seguro permitirlas, en caso de que esté interactuando con uno
          de los recursos que utiliza en nuestro sitio web.
        </Text>

        <span style={estiloDefault}>
          Esta política es efectiva a partir de 11 December 2022 03:02.
        </span>
      </Flex>
    </LandingLayout>
  );
}
