/* eslint-disable prettier/prettier */
/* eslint-disable import/prefer-default-export */
import React from 'react';
import {
  BrowserRouter,
  Routes,
  Route,
  Navigate,
} from 'react-router-dom';
import AvisoPrivacidadView from '../views/AvisoPrivacidadView';
import ErrorView from '../views/Error';
import FBView from '../views/Facebook';
// import HomeView from '../views/Home';
// import LandingView from '../views/Landing';
import OnConstructionView from '../views/OnConstruction';
import PresentacionView from '../views/Presentacion';
// import PersonView from '../views/Person';
// import ResponsiveFooterView from '../views/ResponsiveFooter';
import ZoomView from '../views/Zoom';

// https://github.com/remix-run/react-router/blob/main/docs/getting-started/tutorial.md
export function LuzmaRouter() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<OnConstructionView />} />
        {/* <Route path="person" element={<PersonView />} /> */}
        {/* <Route path="charts" element={<HomeView />} /> */}
        <Route path="zoom" element={<ZoomView />} />
        <Route path="facebook" element={<FBView />} />
        <Route path="presentacion" element={<PresentacionView />} />
        {/* <Route path="sandbox" element={<ResponsiveFooterView />} /> */}
        <Route path="aviso-de-privacidad" element={<AvisoPrivacidadView />} />
        <Route path="error" element={<ErrorView />} />
        <Route path="*" element={<Navigate to="/error" replace />} />
      </Routes>
    </BrowserRouter>
  );
}
