import React, { useEffect } from 'react';
import { Flex, Heading } from '@chakra-ui/react';
import LandingLayout from '../components/layouts/landing';

function ZoomView() {
  useEffect(() => {
    window.location.href = process.env.REACT_APP_URL_ZOOM;
  }, []);

  return (
    <LandingLayout>
      <Flex
        p={['0', '0', '64px']}
        h="75vh"
        direction="column"
        justify="center"
        align="center"
      >
        <Heading as="h2" align="center">
          Redirigiendo a la reunión por Zoom
        </Heading>
      </Flex>
    </LandingLayout>
  );
}

export default ZoomView;
