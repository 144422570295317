import React from 'react';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';
import { chakra } from '@chakra-ui/react';

function MenuItem({ children, isLast, to }) {
  const Link = chakra(NavLink, {
    baseStyle: {
      mb: { base: isLast ? 0 : 8, sm: 0 },
      mr: { base: 0, sm: isLast ? 0 : 8 },
      display: 'block',
    },
  });

  const activeStyle = {
    fontWeight: 'bold',
    textDecoration: 'underline',
    textDecorationStyle: 'solid',
  };

  return (
    <Link to={to} style={({ isActive }) => (isActive ? activeStyle : {})}>
      {children}
    </Link>
  );
}

MenuItem.propTypes = {
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.element])
    .isRequired,
  isLast: PropTypes.bool,
  to: PropTypes.string.isRequired,
};

MenuItem.defaultProps = {
  isLast: false,
};

export default MenuItem;
