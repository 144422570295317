/* eslint-disable prettier/prettier */
import React, { useEffect } from 'react';
import { Flex, Heading } from '@chakra-ui/react';
import LandingLayout from '../components/layouts/landing';

function FBView() {
  const fb = 'https://www.facebook.com/LuzMa-Cooperativista-Ciclos-629978307626754';

  useEffect(() => {
    window.location.href = fb;
  }, []);

  return (
    <LandingLayout>
      <Flex
        p={['0', '0', '64px']}
        h="75vh"
        direction="column"
        justify="center"
        align="center"
      >
        <Heading as="h2" align="center">
          Redirigiendo a Facebook
        </Heading>
      </Flex>
    </LandingLayout>
  );
}

export default FBView;
