import React from 'react';
import PropTypes from 'prop-types';
import { Flex } from '@chakra-ui/react';

import Header from '../sections/Header';

function LandingLayout({ children }) {
  return (
    <Flex direction="column" align="center" maxW={{ xl: '1200px' }} m="0 auto">
      <Header />
      {children}
    </Flex>
  );
}

LandingLayout.propTypes = {
  children: PropTypes.element.isRequired,
};

export default LandingLayout;
