import React, { useState } from 'react';
import { Box, Button, Flex } from '@chakra-ui/react';
import Logo from '../ui/Logo';
import MenuItem from './MenuItem';
import { CloseIcon, MenuIcon } from '../ui/Icons';

function Header() {
  const [show, setShow] = useState(false);
  const toggleMenu = () => setShow(!show);

  return (
    <Flex
      as="nav"
      align="center"
      justify="space-between"
      wrap="wrap"
      w="100%"
      // mb={8}
      p={8}
      // maxH="30vh"
      bg={['primary.500', 'primary.500', 'transparent', 'transparent']}
      color={['white', 'white', 'primary.500', 'primary.500']}
    >
      <Flex align="center">
        <Logo />
      </Flex>

      <Box display={{ base: 'block', md: 'none' }} onClick={toggleMenu}>
        {show ? <CloseIcon /> : <MenuIcon />}
      </Box>

      <Box
        display={{ base: show ? 'block' : 'none', md: 'block' }}
        flexBasis={{ base: '100%', md: 'auto' }}
      >
        <Flex
          align={['center', 'center', 'center', 'center']}
          justify={['center', 'space-between', 'flex-end', 'flex-end']}
          direction={['column', 'row', 'row', 'row']}
          pt={[4, 4, 0, 0]}
        >
          <MenuItem to="/">Inicio</MenuItem>
          <MenuItem to="/ciclos">Ciclos</MenuItem>
          {/* <MenuItem to="/charts">XD</MenuItem> */}
          {/* <MenuItem to="/quien-soy">¿Quién soy?</MenuItem> */}
          <MenuItem to="/zoom" isLast>
            <Button
              size="sm"
              rounded="md"
              color={['primary.500', 'primary.500', 'white', 'white']}
              bg={['white', 'white', 'primary.500', 'primary.500']}
              _hover={{
                bg: [
                  'secondary.100',
                  'secondary.100',
                  'primary.600',
                  'primary.600',
                ],
              }}
            >
              Únete
            </Button>
          </MenuItem>
        </Flex>
      </Box>
    </Flex>
  );
}

export default Header;
