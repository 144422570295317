/* eslint-disable object-curly-newline */
import React from 'react';
import { Box } from '@chakra-ui/react';
import LogoCiclos from './assets/LogoCiclos';

const tamanios = ['24px', '32px', '48px'];

function Logo() {
  return (
    <Box
      w="100px"
      color={['white', 'white', 'background.700', 'background.700']}
    >
      <LogoCiclos tamanios={tamanios} />
    </Box>
  );
}

export default Logo;
