import { extendTheme, withDefaultColorScheme } from '@chakra-ui/react';

const config = {
  cssVarPrefix: 'lm',
  initialColorMode: 'dark',
  useSystemColorMode: false,
};

const colors = {
  primary: {
    50: '#F0F8F9',
    100: '#E2F1F3',
    200: '#C5E3E8',
    300: '#94CCD4', // MiddleBlue
    400: '#7CC0CB',
    500: '#50ABB9',
    600: '#4094A0',
    700: '#295E66',
    800: '#1D4349',
    900: '#060D0F',
  },
  secondary: {
    50: '#F1F6F8',
    100: '#C9DCE4',
    200: '#92BAC8',
    300: '#85B1C1',
    400: '#6AA0B4',
    500: '#528DA3',
    600: '#447688',
    700: '#2B4A55', // Charcoal
    800: '#1B2F36',
    900: '#070C03',
  },
  background: {
    50: '#F3F5F6',
    100: '#DCE2E5',
    200: '#B9C6CB',
    300: '#A1B3BA',
    400: '#8AA0A8',
    500: '#6C8792', // LightSlateGray
    600: '#5F7781',
    700: '#4E626A',
    800: '#2B363B',
    900: '#090B0C',
  },
  raptist: {
    100: '#E5FCF1',
    200: '#27EF96',
    300: '#10DE82',
    400: '#0EBE6F',
    500: '#0CA25F',
    600: '#0A864F',
    700: '#086F42',
    800: '#075C37',
    900: '#064C2E',
  },
  Black: '#010101',
  Cultured: '#F8F8F8',
};

const theme = extendTheme(
  {
    colors,
    semanticTokens: {
      colors: {
        brand: {
          primary: {
            default: 'primary.300',
            _dark: 'primary.500',
          },
          secondary: {
            default: 'secondary.700',
          },
          bg: {
            default: 'background.500',
            _dark: 'background.800',
          },
        },
      },
    },
    config,
  },
  withDefaultColorScheme({ colorScheme: 'telegram' })
);

export default theme;
