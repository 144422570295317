import React, { useEffect } from 'react';
import { Flex, Stack } from '@chakra-ui/react';
import Reveal from 'reveal.js';
import Markdown from 'reveal.js/plugin/markdown/markdown.esm';

import Header from '../sections/Header';

import '../../assets/reveal/reveal.css';
import '../../assets/reveal/theme/black.css';

function PresentacionLayout() {
  useEffect(() => {
    const deck = new Reveal({
      plugins: [Markdown],
      controls: false,
      progress: false,
      slideNumber: false,
      overview: false,
    });
    deck.initialize();

    return () => {
      deck.destroy();
    };
  }, []);

  return (
    <Flex direction="column" align="center" maxW={{ xl: '1200px' }} m="0 auto">
      <Stack h="100vh" w="100%" maxW={{ xl: '1200px' }} m="0 auto">
        <Header />
        <div className="reveal">
          <div className="slides">
            <section>Diapositiva 1</section>
            <section>Diapositiva 2</section>
          </div>
        </div>
      </Stack>
    </Flex>
  );
}

export default PresentacionLayout;
